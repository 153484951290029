<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  moveTotalListAPI,
  summaryByStore,
  summaryByStoreGoodsCategory,
  sumTransferSummary,
  listStoreAllocateStatistics,
  sumListStoreAllocateStatistics,
} from "@/api/store/report"; //仓库报表
// const typesList = ['100201','110102','110103','120102','120103','130102','130103','130104','130105','130106','130107','130108','140303','140304','140305','140306','140307','140308','140310','140311','170202','170203','170205','100201',]
const typesList = [
  "140305",
  "140306",
  "130104",
  "130105",
  "130106",
  "170407",
  "170406",
  "170307",
  "170306",
];
export default {
  name: "jxcList",
  components: { TablePage },
  data() {
    return {
      options: {
        // mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        uuid: "fb438379-ca98-ad21-ae8d-4ccccca355d0",
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        labelWidth: "80",
        check: [], // 选中数据
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/商品编号/商品名称/商品类别编号/商品类别名称/备注",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编号" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "categoryNos", label: "商品类别编号" },
              { filter: "categoryNames", label: "商品类别名称" },
            ],
          },
          this.$select({
            key: "billTypeList",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
                dataKey: (res) => res.data.filter((x) => typesList.includes(x.dictValue)),
              },
              hide: () => this.options?.tabColumnType !== "storeAllocateStatistics",
            },
          }),
          this.$select({
            key: "billTypeList",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
                dataKey: (res) => {
                  return res.data.filter((x) =>
                    typesList.filter((i) => i != "130104").includes(x.dictValue)
                  );
                },
              },
              hide: () => this.options?.tabColumnType === "storeAllocateStatistics",
            },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listInStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listOutStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "sumTransferSummary",
        tabsColumns: [
          {
            title: "仓库调拨汇总表",
            exportOption: {
              exportApi: "exportTransferSummary",
              exportName: "仓库调拨汇总表",
              timeout: 60 * 5 * 1000,
            },
            uuid: "fb438379-ca98-ad21-ae8d-4ccccca355d0",
            getListApi: moveTotalListAPI,
            getSummaryApi: sumTransferSummary,
            type: "moveTotal",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 120,
              },
              {
                prop: "outStoreNo",
                label: "调出仓库编号",
                minWidth: 150,
              },
              {
                prop: "outStoreName",
                label: "调出仓库",
                minWidth: 120,
              },
              {
                prop: "inStoreNo",
                label: "调入仓库编号",
                minWidth: 160,
              },
              {
                prop: "inStoreName",
                label: "调入仓库",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "商品条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "调拨金额",
                minWidth: 150,
              },
              {
                prop: "moveOutQty",
                label: "调出数量",
                minWidth: 150,
              },
              {
                prop: "moveOutPurMoney",
                label: "调出进价金额",
                minWidth: 150,
              },
              {
                prop: "moveOutSaleMoney",
                label: "调出售价金额",
                minWidth: 150,
              },
              {
                prop: "moveOutUnitCostMoney",
                label: "调出成本金额",
                minWidth: 150,
              },
              {
                prop: "moveInQty",
                label: "调入数量",
                minWidth: 150,
              },
              {
                prop: "moveInPurMoney",
                label: "调入进价金额",
                minWidth: 150,
              },
              {
                prop: "moveInSaleMoney",
                label: "调入售价金额",
                minWidth: 150,
              },
              {
                prop: "moveInUnitCostMoney",
                label: "调入成本金额",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "moveOutQty",
              "moveOutPurMoney",
              "moveOutSaleMoney",
              "moveInQty",
              "moveInPurMoney",
              "moveInSaleMoney",
              'moveOutUnitCostMoney',
              'moveInUnitCostMoney'
            ],
          },
          {
            title: "仓库+类别调拨汇总",
            exportOption: {
              exportApi: "exportSummaryByStoreGoodsCategory",
              exportName: "仓库+类别调拨汇总",
              timeout: 60 * 5 * 1000,
            },
            uuid: "935f5a13-9272-ee40-b4bc-c6b65b3db8ad",
            getListApi: summaryByStoreGoodsCategory,
            getSummaryApi: sumTransferSummary,
            type: "summaryByStoreGoodsCategory",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 120,
              },
              {
                prop: "outStoreNo",
                label: "调出仓库编号",
                minWidth: 150,
              },
              {
                prop: "outStoreName",
                label: "调出仓库",
                minWidth: 120,
              },
              {
                prop: "inStoreNo",
                label: "调入仓库编号",
                minWidth: 160,
              },
              {
                prop: "inStoreName",
                label: "调入仓库",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
              },
              // {
              //   prop: 'goodsNo',
              //   label: '商品编码',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsName',
              //   label: '商品名称',
              //   minWidth: 150
              // },
              // {
              //   prop: 'barcode',
              //   label: '商品条码',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsSpec',
              //   label: '规格',
              //   minWidth: 150
              // },
              // {
              //   prop: 'unitName',
              //   label: '单位',
              //   minWidth: 150
              // },
              {
                prop: "unitPriceTaxMoney",
                label: "调拨金额",
                minWidth: 150,
              },
              {
                prop: "moveOutQty",
                label: "调出数量",
                minWidth: 150,
              },
              {
                prop: "moveOutPurMoney",
                label: "调出进价金额",
                minWidth: 150,
              },
              {
                prop: "moveOutSaleMoney",
                label: "调出售价金额",
                minWidth: 150,
              },
              {
                prop: "moveOutUnitCostMoney",
                label: "调出成本金额",
                minWidth: 150,
              },
              {
                prop: "moveInQty",
                label: "调入数量",
                minWidth: 150,
              },
              {
                prop: "moveInPurMoney",
                label: "调入进价金额",
                minWidth: 150,
              },
              {
                prop: "moveInSaleMoney",
                label: "调入售价金额",
                minWidth: 150,
              },
              {
                prop: "moveInUnitCostMoney",
                label: "调入成本金额",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "moveOutQty",
              "moveOutPurMoney",
              "moveOutSaleMoney",
              "moveInQty",
              "moveInPurMoney",
              "moveInSaleMoney",
              'moveOutUnitCostMoney',
              'moveInUnitCostMoney'
            ],
          },
          {
            title: "按仓库汇总",
            exportOption: {
              exportApi: "exportSummaryByStore",
              exportName: "按仓库汇总",
              timeout: 60 * 5 * 1000,
            },
            uuid: "3f9d2ecb-1e9a-8195-8f31-c7ea68d46272",
            getListApi: summaryByStore,
            getSummaryApi: sumTransferSummary,
            type: "summaryByStore",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 120,
              },
              {
                prop: "outStoreNo",
                label: "调出仓库编号",
                minWidth: 150,
              },
              {
                prop: "outStoreName",
                label: "调出仓库",
                minWidth: 120,
              },
              {
                prop: "inStoreNo",
                label: "调入仓库编号",
                minWidth: 160,
              },
              {
                prop: "inStoreName",
                label: "调入仓库",
                minWidth: 120,
              },
              // {
              //   prop: 'categoryName',
              //   label: '商品类别',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsNo',
              //   label: '商品编码',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsName',
              //   label: '商品名称',
              //   minWidth: 150
              // },
              // {
              //   prop: 'barcode',
              //   label: '商品条码',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsSpec',
              //   label: '规格',
              //   minWidth: 150
              // },
              // {
              //   prop: 'unitName',
              //   label: '单位',
              //   minWidth: 150
              // },
              {
                prop: "unitPriceTaxMoney",
                label: "调拨金额",
                minWidth: 150,
              },
              {
                prop: "moveOutQty",
                label: "调出数量",
                minWidth: 150,
              },
              {
                prop: "moveOutPurMoney",
                label: "调出进价金额",
                minWidth: 150,
              },
              {
                prop: "moveOutSaleMoney",
                label: "调出售价金额",
                minWidth: 150,
              },
              {
                prop: "moveOutUnitCostMoney",
                label: "调出成本金额",
                minWidth: 150,
              },
              {
                prop: "moveInQty",
                label: "调入数量",
                minWidth: 150,
              },
              {
                prop: "moveInPurMoney",
                label: "调入进价金额",
                minWidth: 150,
              },
              {
                prop: "moveInSaleMoney",
                label: "调入售价金额",
                minWidth: 150,
              },
              {
                prop: "moveInUnitCostMoney",
                label: "调入成本金额",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "moveOutQty",
              "moveOutPurMoney",
              "moveOutSaleMoney",
              "moveInQty",
              "moveInPurMoney",
              "moveInSaleMoney",
              'moveOutUnitCostMoney',
              'moveInUnitCostMoney'
            ],
          },
          {
            title: "按仓库+类别调拨交叉汇总",
            exportOption: {
              exportApi: "exportStoreAllocateStatistics",
              exportName: "按仓库+类别调拨交叉汇总",
              timeout: 60 * 5 * 1000,
            },
            uuid: "95725c39-3de0-f50f-c1f2-a61516fc37dc",
            type: "storeAllocateStatistics",
            getListApi: listStoreAllocateStatistics,
            getSummaryApi: sumListStoreAllocateStatistics,
            getDataKey: (e) => {
              const { rows, total } = e || {};
              let list = rows.map((item) => {
                const mergedObject = {};
                // 遍历数组
                item.details.forEach((obj) => {
                  Object.assign(mergedObject, {
                    [`${obj.categoryId}.inSaleMoney`]: obj.inSaleMoney,
                    [`${obj.categoryId}.outSaleMoney`]: obj.outSaleMoney,
                    [`${obj.categoryId}.totalSaleMoney`]: obj.totalSaleMoney,
                  });
                });
                return {
                  ...item,
                  ...mergedObject,
                };
              });
              return {
                list,
                total,
              };
            },
            getSummaryData: (e) => {
              const { details } = e?.data || {};
              const mergedObject = {};

              details.forEach((obj) => {
                Object.assign(mergedObject, {
                  [`${obj.categoryId}.inSaleMoney`]: obj.inSaleMoney,
                  [`${obj.categoryId}.outSaleMoney`]: obj.outSaleMoney,
                  [`${obj.categoryId}.totalSaleMoney`]: obj.totalSaleMoney,
                });
              });
              return mergedObject;
            },
            columns: [
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 160,
              },
              {
                prop: "details",
                align: "center",
                label: "",
                getColumnsType: 2,
                getColumns: (list) => {
                  let columns = list[0].columns?.map((item) => ({
                    prop: "categoryName",
                    label: item.categoryName,
                    minWidth: 120,
                    children: [
                      {
                        prop: `${item.categoryId}.inSaleMoney`,
                        label: "调入售价金额",
                        summary: `${item.categoryId}.inSaleMoney`,
                        minWidth: 120,
                      },
                      {
                        prop: `${item.categoryId}.outSaleMoney`,
                        label: "调出售价金额",
                        summary: `${item.categoryId}.outSaleMoney`,
                        minWidth: 120,
                      },
                      {
                        prop: `${item.categoryId}.totalSaleMoney`,
                        summary: `${item.categoryId}.totalSaleMoney`,
                        label: "总售价金额",
                        minWidth: 120,
                      },
                    ],
                  }));
                  return columns;
                },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    seniorSearch() { },
  },
};
</script>
<style lang="scss" scoped></style>
